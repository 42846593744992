<template>
  <div>
    <h3>Messages</h3>
    <p class="my-3">
      You currently have {{ new_message_count }} new and
      {{ read_message_count }} read messages.
    </p>
    <b-row align-v="start">
      <b-col>
        <b-button to="/">Back to Menu</b-button>
      </b-col>
      <b-col class="text-right">
        <b-form-radio-group
          v-model="message_status"
          :options="message_status_options"
          button-variant="outline-primary"
          class="mb-3"
          buttons
        ></b-form-radio-group>
      </b-col>
    </b-row>

    <b-table
      v-if="messages.length"
      striped
      hover
      :items="messages"
      :fields="fields"
      caption-top
      class="mt-1"
    >
      <template #cell(subject)="data">
        <!--bold if status = 'N' -->
        <b-link @click="onViewMessage(data.item.id)">
          <span v-if="data.item.status === 'N'"
            ><b>{{ data.value }}</b></span
          >
          <span v-else>{{ data.value }}</span>
        </b-link>
      </template>
      <template #cell(archive)="data">
        <!-- trash icon -->
        <b-button
          v-if="data.item.status != 'A'"
          :disabled="data.item.status === 'N'"
          size="sm"
          variant="danger"
          @click="archiveMessage(data.item.id)"
        >
          <b-icon icon="trash" />
          Archive
        </b-button>
        <!-- restore icon -->
        <b-button
          v-else
          size="sm"
          variant="info"
          @click="restoreMessage(data.item.id)"
        >
          <b-icon icon="arrow-counterclockwise" />
          Restore
        </b-button>
      </template>
    </b-table>

    <b-modal
      id="modal-message"
      :title="current_message_subject"
      size="xl"
      hide-footer
      ok-only
      ok-title="Close"
    >
      <template #modal-title>
        <!-- h4 like text without break -->
        <span class="h5">{{ current_message_subject }}</span>
        <!-- print button -->
        <b-button size="sm" variant="outline-primary" @click="print" class="ml-2">
          <b-icon icon="printer" />
          Print
        </b-button>
      </template>
      <!-- send and read timestamps -->
      <div id="message-container">
        <div v-if="current_message">
        <p>
          <b>Subject:</b> {{ current_message.subject }}
          <br /><b>Sent:</b> {{ new Date(current_message.ts_sent).toLocaleString() }}
        </p>
        <div v-if="current_message.message_type === 'html'" v-html="current_message.message"></div>
        <div v-else>
          <p style="white-space: pre-wrap;" v-if="current_message">{{current_message.message}}</p>
        </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue"
import VueHtmlToPaper from "vue-html-to-paper"

const print_options = {
  name: "_blank",
  specs: ["fullscreen=no", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
  ],
  timeout: 2000, // default timeout before the print window appears
  //autoClose: true, // if false, the window will not close after printing
  windowTitle: "MS PWS Portal - Print Message" // override the window title
}

Vue.use(VueHtmlToPaper, print_options)


export default {
  name: "Messages.vue",
  data() {
    return {
      current_message: null,
      messages: [],
      message_status: "",
      message_status_options: [
        { value: "", text: "All" },
        { value: "N", text: "New" },
        { value: "R", text: "Read" },
        { value: "A", text: "Archived" }
      ],
      fields: [
        "subject",
        {
          key: "ts_sent",
          label: "Sent",
          sortable: true,
          formatter: (value) => {
            return new Date(value).toLocaleString()
          }
        },
        { key: "archive", label: "Archive/Restore" }
      ]
    }
  },
  mounted() {
    this.fetchMessages()
  },
  computed: {
    new_message_count() {
      // count messages with status = 'N'
      return this.messages.filter((message) => message.status === "N").length
    },
    read_message_count() {
      // count messages with status = 'N'
      return this.messages.filter((message) => message.status == "R").length
    },
    current_message_subject() {
      let title = ""
      if (this.current_message) {
        title += this.current_message.subject
      }
      return title
    }
  },
  watch: {
    message_status() {
      this.fetchMessages()
    }
  },
  methods: {
    async fetchMessages() {
      let path = process.env.VUE_APP_API_URI + "/user-messages"
      if (this.message_status != "") {
        path = path + "?message_status=" + this.message_status
      }
      try {
        let response = await this.axios.get(path)
        console.log(response)
        // clear messages
        this.messages = []
        if (response.data) {
          for (const msg of response.data) {
            console.log(msg)
            this.messages.push(msg)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchMessage(id) {
      if (this.current_message && this.current_message.id === id) {
        return
      }
      const path =
        process.env.VUE_APP_API_URI + "/user-message?message_id=" + id
      try {
        let response = await this.axios.get(path)
        console.log(response)
        if (response.data) {
          this.current_message = response.data.message
        }
      } catch (error) {
        console.log(error)
      }
    },
    async archiveMessage(id) {
      const path = process.env.VUE_APP_API_URI + "/user-message-status"
      const params = {
        message_id: id,
        message_status: "A"
      }
      try {
        let response = await this.axios.post(path, params)
        console.log(response)
        if (response.data && response.data.msg === "OK") {
          // change local status to 'A'
          this.messages.find((message) => message.id === id).status = "A"
        }
      } catch (error) {
        console.log(error)
      }
    },
    async restoreMessage(id) {
      const path = process.env.VUE_APP_API_URI + "/user-message-status"
      const params = {
        message_id: id,
        message_status: "R"
      }
      try {
        let response = await this.axios.post(path, params)
        console.log(response)
        if (response.data && response.data.msg === "OK") {
          // change local status to 'A'
          this.messages.find((message) => message.id === id).status = "R"
        }
      } catch (error) {
        console.log(error)
      }
    },
    async onViewMessage(id) {
      await this.fetchMessage(id)
      // set local status to 'R'
      this.messages.find((message) => message.id === id).status = "R"
      this.$bvModal.show("modal-message")
    },
    print() {
      print_options.windowTitle = "MS PWS Portal Message - " + this.current_message.subject
      this.$htmlToPaper("message-container", print_options)
      //const modal = document.getElementById("modal-message")
      //const cloned = modal.cloneNode(true)
      //let section = document.getElementById("print")

      //if (!section) {
      //  section = document.createElement("div")
      //  section.id = "print"
      //  document.body.appendChild(section)
      //}

      //section.innerHTML = ""
      //section.appendChild(cloned)
      //window.print()
      //// delete the print section
      //section.remove()
      ////section.innerHTML = ""
    }
  }
}
</script>

<style>
</style>
