<template>
  <div class="about">
    <h3 class="mt-4 mb-4">About the Mississippi Public Water System Portal</h3>
    <p>
      This portal enables information sharing between Mississippi Public Water
      Systems and the Mississippi State Department of Health, Bureau of Public Water
        Supply. Usage of this portal is intended only for authorized users of
      Mississippi Public Water Systems.
    </p>
  </div>
</template>
<script>

export default {
     name: "About",
};
</script>
