<template>
  <div>
    <h3 class="mt-2 mb-4">Settings</h3>
    <h5>Notifications</h5>
    <div class="mt-4">
    <b-form-checkbox v-model="settings.notifications.email_sample_results"
      >Notify me when new sample results arrive.</b-form-checkbox
    >
    </div>
    <b-button @click="saveUserPreferences" class="mt-4" variant="primary">Save Preferences</b-button>
    <b-button to="/" class="ml-3 mt-4" variant="primary">Finished</b-button>
    <b-alert class="mt-3" variant="success" :show="show_saved_alert" dismissible>Settings Saved</b-alert>
  </div>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      settings: {
        notifications: { email_sample_results: false }
      },
      show_saved_alert: 0
    }
  },
  mounted() {
    this.fetchUserPreferences()
  },
  methods: {
    async fetchUserPreferences() {
      const url = process.env.VUE_APP_API_URI + "/user-preferences"
      const result = await this.axios.get(url)
      console.debug(result)
      if (result.data && result.data.notifications) {
        this.settings = result.data
      }
      console.debug(this.settings)
    },
    async saveUserPreferences() {
      const url = process.env.VUE_APP_API_URI + "/save-user-preferences"
      const result = await this.axios.post(url, this.settings)
      console.debug(result)
      if (result.status == 200) {
        this.show_saved_alert = 3
      }
    },
  }
}
</script>
