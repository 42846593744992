<template>
  <div>
    <h3 class="mt-2 mb-4">Email Test</h3>
    <p>
      Send a test e-mail to yourself to help diagnose issues receiving e-mail
      notifications.
    </p>
    <div class="mt-4">
      <div v-if="email_tests.length > 9">
        Test limit exceeded. Wait and try again later.
      </div>
      <b-button
        :disabled="email_tests.length > 9 || this.busy"
        @click="runEmailTest"
        variant="primary"
      >
        <span v-if="email_sent">Re-</span>Send Test E-Mail
      </b-button>
      <b-alert class="mt-3" variant="success" :show="show_sent_alert"
        >E-Mail Sent</b-alert
      >
      <b-alert class="mt-3" variant="error" :show="show_notsent_alert"
        >E-Mail Not Sent</b-alert
      >
    </div>
    <b-card title="Recent Tests" class="mt-4">
      <b-card-text>
        <p>
          These are the results of e-mail tests that you have run in the last 24
          hours. It may take a few minutes, but either
          <strong>Delivered</strong> or <strong>Bounced</strong> should become
          populated.
        </p>
        <p>
          If the e-mail was <strong>Delivered</strong>, your e-mail service
          accepted the message, so it should be in your Inbox or maybe a
          <em>Spam</em> folder. If the e-mail <strong>Bounced</strong>, your
          e-mail provider is rejecting the message. Please configure your
          mailbox to accept e-mail from the domain mailer.mswater.us
        </p>
        <strong>E-Mail Address:</strong> &nbsp;<span>{{
          test_email_address
        }}</span>
        <p class="mt-3" v-if="setup_complete && !email_tests.length">
          No recent tests found.
        </p>
      </b-card-text>
      <b-table
        bordered
        striped
        responsive
        v-if="email_tests.length"
        :fields="fields"
        :items="email_tests"
      />
      <b-row align-v="center">
        <b-col md="auto">
          <b-button :disabled="busy || autocheck" variant="primary" @click="fetchEmailTests">
            Reload Test Results
          </b-button>
        </b-col>
        <b-col>
          <b-form-checkbox v-model="autocheck">
            Auto Check
            <span v-if="autocheck">
            (<span>{{ timer }}</span> seconds)
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col>
          <strong>Last Update:</strong>
          {{ last_update }}
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
function formatDateString(dtstr) {
  if (dtstr) {
    return new Date(dtstr).toLocaleString()
  } else {
    return dtstr
  }
}

export default {
  name: "Settings",
  data() {
    return {
      busy: false,
      autocheck: true,
      timer: 30,
      setup_complete: false,
      test_email_address: "",
      email_tests: [],
      show_sent_alert: false,
      show_notsent_alert: false,
      email_sent: false,
      last_update: "",
      fields: [
        { key: "ts", label: "Sent", formatter: formatDateString },
        { key: "sent", label: "Delivered" },
        { key: "bounced", label: "Bounced" }
      ]
    }
  },
  mounted() {
    this.fetchEmailTests()
    //setInterval(this.autoCheck, 30000)
    setInterval(this.countBack, 1000)
  },
  methods: {
    async countBack() {
      this.timer = this.timer - 1
      if (this.autocheck && this.timer == 0) {
        await this.fetchEmailTests()
      } else if (this.timer < 0) {
        this.timer = 30
      }
    },
    async fetchEmailTests() {
      this.show_sent_alert = false
      this.show_notsent_alert = false
      const url = process.env.VUE_APP_API_URI + "/email-tests"
      this.busy = true
      try {
        const result = await this.axios.get(url)
        console.debug(result)
        if (result.data && result.data.tests) {
          this.email_tests = result.data.tests
        }
        if (result.data && result.data.email) {
          this.test_email_address = result.data.email
        }
        this.last_update = new Date().toLocaleString()
      } finally {
        this.busy = false
        this.setup_complete = true
      }
    },
    async runEmailTest() {
      // reset
      this.show_sent_alert = false
      this.show_notsent_alert = false
      this.busy = true
      try {
        const url = process.env.VUE_APP_API_URI + "/send-test-email"
        const result = await this.axios.post(url)
        console.debug(result)
        if (result.data && result.data.tests) {
          this.email_tests = result.data.tests
        }
        //const result = { status: 200 }
        if (result.status == 200) {
          this.show_sent_alert = true
          this.email_sent = true
        } else {
          this.show_notsent_alert = true
        }
        this.last_update = new Date().toLocaleString()
      } finally {
        this.busy = false
      }
    }
  }
}
</script>
